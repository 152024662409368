import './Unsubscribe.css';
import { variables } from '../Variables';
import image from '../assets/email_sub.png'
import { useState, useEffect } from 'react';

const Unsubscribe = () => {
    const [mainMessage, setMainMessage] = useState("Chvilku ztrpení, probíhá zrušení odběru");
    const [message, setMessage] = useState("");

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const id = query.get('id');
        const code = query.get('code');

        if (!id || !code) {
            setMainMessage('Špatně zadaná URL');
            return;
        }

        fetch(variables.API_URL + `pricealerts?id=${id}&code=${code}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            code,
        }),
        })
        .then(response => {
            if (response.ok) {
                setMainMessage("Zrušení odběru proběhlo úspěšně");
                setMessage("Děkujeme, za využití naší služby.")
            }
        })
        .catch(error => {
            setMainMessage("Zrušení odběru se nepovedlo");
            setMessage("Zkuste na tlačítko v emailu kliknout znovu");
        });
    }, []);

    return (
        <div className='w-11/12 xl:w-10/12 2xl:w-9/12'>
            <div  className='p-4 flex lg:flex-row flex-col lg:items-center lg:justify-between'>
                <div className='lg:max-w-lg 2xl:max-w-xl'>
                    <div>
                    <h1 className='font-bold text-3xl md:text-6xl'>{mainMessage}</h1>
                    <p className='max-w-sm text-lg mt-6 mb-7'>{message}</p>
                    </div>                    
                </div>
                <div>
                    <img className='max-lg:mt-9' src={image} alt='mainImage'/>
                </div>
            </div>
        </div>
    )
}

export default Unsubscribe;