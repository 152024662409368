import './EmailConsent.css';

const EmailConsent = () => {
    return (
        <div className='w-11/12 xl:w-10/12 2xl:w-9/12'>
            <h2 className='font-bold text-2xl md:text-4xl'>Souhlas se zpracováním osobních údajů</h2>
            <p>Správce osobních údajů: Jakub Režňák, web: https://www.zalandoalert.cz/, e-mail: zalandoalert@seznam.cz (dále jen „správce“).</p>

            <h3 className='font-bold text-l md:text-xl mt-5'>I. Rozsah zpracování</h3>
            <p>Vaše osobní údaje (dále jen “OÚ”) budeme na základě přihlášení k odběru upozorněná o změně ceny zpracovávat v následujícím rozsahu: e-mailová adresa.</p>
        
            <h3 className='font-bold text-l md:text-xl mt-5'>II. Účel zpracování</h3>
            <p>Vaše OÚ budeme zpracovávat pro informační účely: oznamování slevových akcí, výprodejů, a to zejména prostřednictvím e-mailu.</p>

            <h3 className='font-bold text-l md:text-xl mt-5'>III. Doba zpracování</h3>
            <p>Vaše OÚ budeme zpracovávat po dobu, dokud se nerozhodnete souhlas zrušit a to zrušením odběru. Zrušení vám bude vždy nabídnuto v emailové zprávě o změně ceny daného produktu, u kterého jste souhlas udělil.</p>

            <h3 className='font-bold text-l md:text-xl mt-5'>V. Vaše práva týkající se zpracování</h3>
            <p>Poskytnutí údajů je zcela dobrovolné - v důsledku jejich neposkytnutí vám emaily nemůžeme zasílat. Po dobu zpracování vašich OÚ máte právo vzít souhlas kdykoli zpět.</p>
        </div>        
    )
}

export default EmailConsent;