import './Footer.css';

const Footer = () => {
    return (
        <div className='w-11/12 xl:w-10/12 2xl:w-9/12'>
            <footer className='place-self-stretch'>
                <div className='border-t border-grey-200 p-8 text-center'>
                <p>Created by <a className='font-bold' href='https://github.com/jakubreznak'>jakubreznak</a></p>
                </div>
            </footer>

        </div>
    )
}

export default Footer;