import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Confirmation from './pages/Confirmation'
import Unsubscribe from './pages/Unsubscribe'
import Home from './pages/Home'
import Footer from './pages/Footer';
import Header from './pages/Header';
import EmailConsent from './pages/EmailConsent';

const App = () => {  

  return (
    <div className='grid place-items-center 2xl:gap-y-20'>
      <Header />

      <BrowserRouter>
        <Routes>
            <Route path="confirmation" element={<Confirmation />} />
            <Route path="unsubscribe" element={<Unsubscribe />} />
            <Route path="consent" element={<EmailConsent />} />
            <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;
