import './Header.css';
import logo from '../assets/favicon.png'

const Header = () => {
    return (
        <div className='w-11/12 xl:w-10/12 2xl:w-9/12'>
            <a className='inline-block' href='/'><img className='place-self-start' width="120" height="120" src={logo} alt='logo'/></a>
        </div>
    )
}

export default Header;