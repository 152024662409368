import './Home.css';
import { useState, useEffect, useCallback } from 'react';
import { variables } from '../Variables';
import image from '../assets/shop.png'
import arrow from '../assets/arrow-down.svg'
import browserZalert from '../assets/BrowserZalertUrl.png'
import browserZalando from '../assets/BrowserZalandoUrl.png'

const Home = () => {
    const ZALANDO_URL = "https://www.zalando.cz/";
    const [formData, setFormData] = useState({});
    const [stateMessage, setStateMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [emailError, setEmailError] = useState("");

    const handleChange = (event) => {
        if (!isValidEmail(event.target.value)) {
            setEmailError("Špatný tvar emailu")
        } else {
            setEmailError("");
        }

        setFormData({
        ...formData,
        [event.target.name]: event.target.value
        });
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

      const GetAndSetItemUrl = useCallback((currentUrl) => {
        const url = currentUrl; 
        const parts = url.split('/');
        const path = parts.slice(3).join('/'); 
        setFormData((formData) => ({
            ...formData,
            "url": ZALANDO_URL + path
        }));
    }, [ZALANDO_URL]);

    useEffect(() => {
        GetAndSetItemUrl(window.location.href);
    }, [GetAndSetItemUrl]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setStateMessage("Vydržte váš požadavek se zpracovává.")

        fetch(variables.API_URL + 'pricealerts', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json'
        }
        })
        .then((response) => response.json())
        .then((data) => {
            setStateMessage("Potvrďte odběr ve vašem emailu.");
        })
        .catch((error) => {
            setStateMessage("Něco se nepovedlo, zkuste to prosím znovu.");
            setIsError(true);
        });
    }

    return (
        <>
            <div className='w-11/12 xl:w-10/12 2xl:w-9/12'>
                <div  className='p-4 flex lg:flex-row flex-col lg:items-center lg:justify-between'>
                    <div className='lg:max-w-lg 2xl:max-w-xl'>
                        <div>
                        <h1 className='font-bold text-3xl md:text-6xl'>Oznámení o změně ceny produktu</h1>
                        <p className='max-w-sm text-lg mt-6 mb-7'>Stačí zadat váš email a my vás budem vždy informovat o změně ceny vašeho oblíbeného produktu</p>
                        </div>
                        <form className='flex flex-col sm:flex-row' onSubmit={handleSubmit}>
                        <input className='sm:border-r-0 border-2 p-4 sm:rounded-tr-none sm:rounded-br-none rounded-md'
                        type="text" name="email" placeholder='Email' onChange={handleChange} />
                        <button disabled={!(formData.url && formData.url.length > ZALANDO_URL.length) || emailError.length > 0 || formData.email == null} className={`max-sm:mt-2 p-4 sm:rounded-tl-none sm:rounded-bl-none rounded-md ${!(formData.url && formData.url.length > ZALANDO_URL.length) || emailError.length > 0 || formData.email == null ? "bg-orange-300 cursor-not-allowed" : "bg-orange-400 hover:bg-orange-500"} text-white font-bold`} type="submit">Odebírat</button>
                        </form>
                        <p className={`mt-2 text-red-600 ${emailError.length === 0 ? "invisible" : "visible"}`}>{emailError}</p>
                        <p className={`mt-2 ${isError === false ? "text-green-600" : "text-red-600"}`}>{stateMessage}</p>
                        <p className='text-xs text-gray-400 max-w-sm'>Odebíráním souhlasíte s <a className='text-sky-500' target="_blank" href='/consent'>podmínkami zpracování osobních údajů</a>. Kdykoliv se můžete odhlásit.</p>
                    </div>
                    <div>
                        <img className='max-lg:mt-9' src={image} alt='mainImage'/>
                    </div>
                </div>
            </div>
            <div className='w-14 animate-bounce max-sm:hidden 2xl:mt-9'>
                <img src={arrow} alt='arrow down'/>
            </div>
            <div className='w-11/12 xl:w-10/12 2xl:w-9/12'>
                <section>
                <h2 className='font-bold text-3xl md:text-5xl mt-5'>Jak to funguje</h2>
                <p className='max-w-sm text-lg mt-6 mb-7'>Přidejte do url na stránce Zalando pouze slovo alert a pak již stačí zadat vaší emailovou adresu</p>
                <img className='mb-9' src={browserZalando} alt='zalandoBrowser'/>
                <img className='mb-9' src={browserZalert} alt='zalertBrowser'/>
                </section>
            </div>            
        </>
    )
}

export default Home;